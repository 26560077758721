<template>
  <AppHeader
    :title="$translate(section.title)"
    :has-error="!!errorMessage"
    display-back-button
    :can-proceed="canProceed"
    @next="next"
    @back="back"
  />
  <v-container>
    <div class="camera">
      <v-avatar
        size="200"
        class="mx-auto camera-circle"
        color="#E5E7EB"
      >
        <CameraComponent
          ref="cameraInstance"
          :resolution="{ width: 712, height: 712 }"
          :autoplay="false"
          facing-mode="user"
          data-qa="camera-trigger"
          @started="cameraLoading = false"
          @resumed="cameraLoading = false"
          @error="handleError"
        />
        <v-img
          v-if="visitStore.photo.value !== undefined"
          :src="visitStore.photo.value"
        />
        <CameraStatus
          v-if="!cameraReady && visitStore.photo.value === undefined"
          :camera-started="cameraStarted"
          :camera-loading="cameraLoading"
          :camera-forbidden="cameraForbidden"
          @start-camera="startCamera"
        />
      </v-avatar>
    </div>
    <div
      class="infos px-8 text-center"
    >
      <div class="photo-title my-4">
        {{ $translate('photoSectionTitle') }}
      </div>
      <div class="description">
        {{ $translate('photoSectionDescription') }}
      </div>
      <CameraActions
        v-if="cameraReady || visitStore.photo.value"
        data-qa="photo-trigger-button"
        @take-photo="takePhoto"
        @reset-photo="resetPhoto"
      />
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref, computed } from 'vue';
import { useSectionNavigation } from '@/dynamic-form/composables/use-section-navigation';
import { dynamicFormService } from '@/dynamic-form/dynamic-form-service';
import AppHeader from '@/components/AppHeader.vue';
import { useRoute } from 'vue-router';
import type { VisitFormSectionType, IVisitFormInputFieldsSection } from '@einfachgast/shared';
import { useVisitStore } from '@/store/visit';

const CameraComponent = defineAsyncComponent(() => import('simple-vue-camera'));
const CameraStatus = defineAsyncComponent(() => import('@/components/dynamic-form/renderers/photo/CameraStatus.vue'));
const CameraActions = defineAsyncComponent(() => import('@/components/dynamic-form/renderers/photo/CameraActions.vue'));

const route = useRoute();
const section = dynamicFormService.getCurrentStep(route.params.step as VisitFormSectionType) as IVisitFormInputFieldsSection;
const visitStore = useVisitStore();

const cameraStarted = ref(false);
const cameraLoading = ref(true);

const cameraForbidden = ref(false);
const errorMessage = ref<string>();

const { back, next } = useSectionNavigation();

const cameraInstance = ref<typeof CameraComponent>();

const startCamera = () => {
  if (cameraStarted.value) {
    return;
  }
  
  cameraInstance?.value?.start();
  cameraStarted.value = true;
};

const takePhoto = async () => {
  const blob = await cameraInstance?.value?.snapshot();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    visitStore.photo.value = reader.result as string;
  };
  cameraInstance?.value?.pause();
};

const resetPhoto = () => {
  visitStore.photo.value = undefined;
  cameraLoading.value = true;
  cameraInstance?.value?.resume();
  startCamera();
};

const canProceed = computed(() => {
  if (cameraForbidden.value) {
    return true;
  }
  return visitStore.photo.value !== undefined;
});

const handleError = (error: unknown) => {
  if ((error as DOMException).name === 'NotAllowedError') {
    cameraForbidden.value = true;
    return;
  }
  // this console error is here on purpose
  console.error(error);
  errorMessage.value = (error as Error).message;
};

const cameraReady = computed(() => cameraStarted.value && !cameraLoading.value );

</script>
<style lang="scss">
.camera {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.camera-circle {
  position: relative;
}
.photo-title  {
  font-size: 1.25rem;
}
.description {
  color: #9E9E9E;
  font-size: 1.1rem;
  margin: 40px 0;
}

</style>

